import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import { locationRouteRequestSchema as schema } from '../../../shared/schemas/location-route-request-schema'
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'

const service = apiClient.service('location-route-requests')

export default ({ name, app }) => {
  app.on('initialized', () => {
    const updateEntities = app.getSequence('locationRouteRequests.updateEntities')
    const updateRequesting = app.getSequence('locationRouteRequests.updateRequesting')
    const setList = app.getSequence('locationRouteRequests.updateList')

    apiClient.on('login',  () => {
      // Computed state is not available until next tick.
      setTimeout(updateRequesting, 0)
    })

    service.on('patched', (locationRouteRequest) => {
      locationRouteRequest = schema.cast(locationRouteRequest)
      updateEntities({ entities: { locationRouteRequests: [locationRouteRequest] } })
      updateRequesting()

      const list = app.getState('locationRouteRequests.list')
      const route = app.getState('route')

      if (route.key !== 'toLocationRouteRequests') {
        return
      }

      const currentStatus = route.params.filter || 'requesting'
      let ids = list.ids

      if (locationRouteRequest.status === currentStatus) {
        ids = Array.from(new Set([...list.ids, locationRouteRequest._id]))
      } else if (list.ids.includes(locationRouteRequest._id)) {
        ids = list.ids.filter((id) => id !== locationRouteRequest._id)
      }
      setList({ result: { ids } })
    })
  })

  return {
    state: {
      ...createCrudState({ entityType: 'locationRouteRequests', storePrefix: name }),
      requestingCount: 0,
    },
    sequences,
    providers: createProviders({ name, service, schema }),
    catch: [
      redirectUnauthorized(),
      [
        Error,
        showFeedbackFromError({ title: 'We encountered an error' }),
        ({ props: { error, errorPath = name }, store }) => {
          store.set(state`errors.${errorPath}`, error)
        },
      ],
    ],
  }
}
