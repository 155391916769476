import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { set, equals, when } from 'cerebral/factories'
import { props, state } from 'cerebral'

export const find = [
  actions.find,
  saveEntities(),
]

export const findForList = [
  set(props`useRouteParams`, true),
  actions.clearList,
  actions.find,
  actions.setList,
  saveEntities(),
]

export const findHolidays = [
  actions.findHolidays,
]

export const findThemes = [
  actions.findThemes,
]

export const findForCalendar = [
  when(state`route.path`, (path) => path.includes('publication')),
  {
    true: [
      set(props`useRouteParams`, false),
    ],
    false: [
      set(props`useRouteParams`, true),
    ],
  },
  actions.find,
  saveEntities(),
  actions.mapCalendarFields,
]

export const selectCalendarTheme = [
  actions.selectCalendarTheme,
]

export const setPaperSizeName = [
  actions.setPaperSizeName,
]

export const setFontSizeName = [
  actions.setFontSizeName,
]

export const setPaperOrientation = [
  actions.setPaperOrientation,
]

export const setPrintSingleLine = [
  actions.setPrintSingleLine,
]

export const setHideEventDescription = [
  actions.setHideEventDescription,
]

export const setEventTypeHidden = [
  actions.setEventTypeHidden,
]

export const setEventLocationHidden = [
  actions.setEventLocationHidden,
]

export const setEventCalendars = [
  actions.setEventCalendars,
]

export const setWeeklyCalendarLayout = [
  actions.setWeeklyCalendarLayout,
]

export const get = [
  actions.get,
  saveEntities(),
]

export const create = [
  actions.create,
  saveEntities(),
  showFeedback({ title: `Import completed successfully`, type: 'success' }), // This message will not be appropriate if create is used for other purposes.
]

export const resetUploadState = [
  actions.resetUploadState,
]

export const setUploadErrorMessage = [
  actions.setUploadErrorMessage,
]

const successFeedback = (actionCompleted) => [
  equals(props`values.eventType`),
  {
    Event: showFeedback({ title: `Event ${actionCompleted}`, type: 'success' }),
    Menu: showFeedback({ title: `Meal ${actionCompleted}`, type: 'success' }),
  },
]

export const redirectToList = [
  when(state`events.lastListView`),
  {
    true: [
      set(props`path`, state`events.lastListView`),
      route.toPath,
    ],
    false: [
      equals(props`values.eventType`),
      {
        Event: route.toEventCalendar,
        Menu: route.toMealsCalendar,
      },
    ],
  },
]

export const save = [
  actions.save,
  saveEntities(),
  successFeedback('saved'),
  redirectToList,
]

export const changeEventTime = [
  actions.changeEventTime,
  successFeedback('changed'),
]

export const remove = [
  actions.remove,
  successFeedback('deleted'),
  findForList,
]

export const togglePublished = [
  actions.togglePublished,
  saveEntities(),
]

export const setEditRangeVisible = [
  actions.setEditRangeVisible,
]

export const setCalendarState = [
  actions.setCalendarState,
]
