import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import { invitationSchema as schema } from '../../../shared/schemas/mobile-user-invitation-schema'
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'

const service = apiClient.service('invitations')

export default ({ name }) => {
  return {
    state: {
      ...createCrudState({ entityType: 'invitations', storePrefix: name }),
      upload: { created: 0, updated: 0, loading: false, queue: [], success: null, error: '' },
    },
    sequences,
    providers: {
      ...createProviders({ name, service, schema }),
    },
    catch: [
      redirectUnauthorized(),
      [
        Error,
        showFeedbackFromError({ title: 'We encountered an error' }),
        ({ props: { error, errorPath = name }, store }) => {
          store.set(state`errors.${errorPath}`, error)
        },
      ],
    ],
  }
}
