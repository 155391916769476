import { map, pick } from 'ramda'
import apiClient from '../../lib/util/api-client'
import { crudActions } from '../factories'

const attendanceService = apiClient.service('event-attendances')

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'eventAttendances' })

export const findIdsForInstance = async ({ props: { eventInstance } }) => {
  let attendances = []
  let totalCount = -1

  while (totalCount === -1 || totalCount > attendances.length) {
    const { total, data } = await attendanceService.find({ query: { eventInstance, $select: '_id profile participationStatus', $limit: 50, $skip: attendances.length } })
    totalCount = total
    attendances = attendances.concat(map(pick(['_id', 'profile', 'participationStatus']), data))
  }

  return { eventAttendanceIds: attendances }
}
