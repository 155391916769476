const { state } = require('cerebral')

export const entityFromState = (type, source) => state`${type || ''}.entities.${source || ''}`

export const expandEntity = (type, source) => (get) => {
  const id = get(source)
  if (type && id) {
    return get(entityFromState(type, id))
  }
}

export const expandEntities = (type, source) => (get) => {
  const ids = get(source)
  return ids.map((id) => get(entityFromState(type, id))).filter(Boolean)
}
