import { props } from 'cerebral'
import { debounce, set } from 'cerebral/factories'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import { sequences as route } from '../route'
import * as actions from './actions'

export const find = [
  //
  actions.useTimeRange,
  actions.find,
  saveEntities(),
]

export const findForCalendar = [
  //
  actions.useTimeRange,
  actions.find,
  saveEntities(),
  actions.mapCalendarFields,
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.useTimeRange,
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Event instance saved', type: 'success' }),
  route.toEventInstances,
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Event instance cleared', type: 'success' }),
  find,
]

export const dashboardRefresh = [
  //
  debounce(50),
  {
    continue: actions.dashboardRefresh,
    discard: [],
  }
]

export const dashboardSetTimeRange = [
  //
  actions.dashboardSetTimeRange,
  dashboardRefresh,
]

export const dashboardSetCalendar = [
  //
  actions.dashboardSetCalendar,
  dashboardRefresh,
]

export const dashboardDataReset = [
  //
  actions.dashboardDataReset,
]
