import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import { eventInstanceSchema as schema } from '../../../shared/schemas/event-instance-schema'
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'

const service = apiClient.service('event-instances')

export default ({ name }) => ({
  state: {
    ...createCrudState({ entityType: 'eventInstances', storePrefix: name }),
    dashboard: {
      selectedFilter: 'all',
      startDate: null,
      endDate: null,
      data: null,
    },
  },
  sequences,
  providers: createProviders({ name, service, schema }),
  catch: [
    redirectUnauthorized(),
    [
      Error,
      showFeedbackFromError({ title: 'We encountered an error' }),
      ({ props: { error, errorPath = name }, store }) => {
        store.set(state`errors.${errorPath}`, error)
      },
    ],
  ],
})
