import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { set } from 'cerebral/factories'
import { props } from 'cerebral'

export const find = [
  //
  actions.find,
  actions.setCalendarDefault,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setCalendarDefault,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  actions.setCalendarDefault,
  saveEntities(),
  showFeedback({ title: 'Calendar saved', type: 'success' }),
  route.toEventCalendar,
]

export const remove = [
  //
  actions.remove,
  findForList,
  actions.changeCalendar,
  showFeedback({ title: 'Calendar deleted', type: 'success' }),
  route.toEventCalendar,
]

export const changeCalendar = [
  //
  actions.changeCalendar,
  route.toEventCalendar,
]
