import { state, moduleState } from 'cerebral'
import { fromPairs, groupBy, pluck, prop, uniq } from 'ramda'
import { profileSchemaDeleted, profileSchemaUserTypes } from '../../../shared/schemas/profile-schema'
import { crudActions } from '../factories'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'profiles' })

export const loadById = ({ props: { mobileUserIds, profileIds }, get }) => {
  if (mobileUserIds) {
    const ids = mobileUserIds.filter(Boolean).map((id) => ({ id, profile: get(state`profiles.mobileUserIdToProfileId.${id}`) }))
    const { missing = [] } = groupBy(({ profile }) => (profile ? 'present' : 'missing'), ids)
    return { useRouteParams: false, query: { mobileUser: missing.length ? { $in: uniq(pluck('id', missing)) } : null } }
  } else if (profileIds) {
    const ids = profileIds.filter(Boolean).map((id) => ({ id, profile: get(state`profiles.entities.${id}`) }))
    const { missing = [] } = groupBy(({ profile }) => (profile ? 'present' : 'missing'), ids)
    return { useRouteParams: false, query: { _id: missing.length ? { $in: uniq(pluck('id', missing)) } : null } }
  }
}

export const adjustFilters = ({ props: { useRouteParams, query = {} }, get }) => {
  // This logic is duplicated from crudActions.
  if (useRouteParams) {
    const params = get(state`route.params`) || {}
    const querystring = get(state`route.query`) || {}
    useRouteParams = false
    query = { ...query, ...params, ...querystring }
  }

  let deleted = { $ne: true }
  if (query.userTypes === 'deactivated') {
    deleted = true
    query.userTypes = undefined
  }
  query.deleted = deleted
  return { query, useRouteParams }
}

export const saveMobileUserMapping = ({ props: { entities: { profiles = [] } = {} }, store }) => {
  const idMap = fromPairs(profiles.filter(prop('mobileUser')).map(({ _id, mobileUser }) => [mobileUser._id || mobileUser, _id]))
  store.merge(state`profiles.mobileUserIdToProfileId`, idMap)
}

export const updateUserTypes = async ({ props: { id, userTypes }, profilesService }) => {
  const data = await profileSchemaUserTypes.validate({ userTypes })
  const profile = await profilesService.patch(id, data)
  return { entities: { profiles: [profile] } }
}

export const reactivate = async ({ props: { id }, profilesService }) => {
  const data = await profileSchemaDeleted.validate({ deleted: false, deletedAt: null, deletedBy: null })
  const profile = await profilesService.patch(id, data)
  return { entities: { profiles: [profile] } }
}

export const acknowledgeBirthday = async ({ props: { id }, profilesService }) => {
  const profile = await profilesService.patch(id, { birthdateAcknowledgedAt: new Date().getFullYear() })
  return { entities: { profiles: [profile] } }
}

export const getTodaysBirthdays = async ({ profilesService: service, store, get }) => {
  const currentUser = get(state`account.currentUser`)
  if (currentUser?.canAccessCommandCenter) {
    const { data: birthdays = [] } = await service.find({ filter: 'birthdays' })
    store.set(moduleState`todaysBirthdays`, birthdays)
  }
}

export const setLevelOfCare = ({ props: { value }, store }) => {
  store.set(moduleState`filter.levelOfCare`, value)
}
