import Bluebird from 'bluebird'
import { moduleState, state } from 'cerebral'
import IMask from 'imask'
import { assocPath, mergeDeepRight, toLower, pick } from 'ramda'
import { parseAddress } from 'vladdress'

import { phoneMaskNoExtension } from '../../../shared/format'
import { invitationSchema } from '../../../shared/schemas/mobile-user-invitation-schema'
import { crudActions } from '../factories'
import { DateTime } from 'luxon'
import { toDateTime } from '../../lib/util/datetime'
import { getGeoCoordinates } from '../../../api/lib/mapbox'
import { isNumber } from 'ramda-adjunct'

const actions = crudActions({ name: 'invitations' })

export const { find, setList, clearList, get, remove, save: update } = actions

export const save = async (context) => {
  const { props: { values } = {}, app, get } = context
  let { userType, product: productSku, address } = values

  const timezone = get(state`account.currentUser.timezone`)
  const showFeedback = app.getSequence('feedback.showFeedback')
  const saveProfile = app.getSequence('profiles.saveWithoutFeedback')
  const findProduct = app.getSequence('rideProducts.find')
  const saveProductSubscription = app.getSequence('rideProductSubscriptions.saveWithoutFeedback')

  if (address) {
    try {
      if (typeof address === 'string') {
        const { addressLine1: street1, addressLine2: street2, placeName: city, stateName: state, zipCode: zip } = parseAddress(address) || {}
        address = { street1, street2, city, state, zip }
      }

      const addressCoordinates = await getGeoCoordinates(address)

      if (isNumber(addressCoordinates?.latitude) && isNumber(addressCoordinates?.longitude)) {
        values.address = address
        values.addressCoordinates = addressCoordinates
      }
    } catch (error) {
      console.error({ err: error }, 'Could not update profile address geo coordinates.')
    }
  }

  let result = await saveProfile({ values: { ...values, userTypes: [userType] } })
  const { entities: { profiles: [profile = {}] } = {} } = result || {}

  if (productSku) {
    try {
      const { entities: { rideProducts: [product] = [] } = {} } = await findProduct({ query: { sku: productSku } })
      if (product) {
        // create a product subscription the provided product sku
        const startAt = DateTime.max(toDateTime(product.startAt, timezone), DateTime.local({ zone: timezone }))
        const subscriptionData = pick(['expireAt', 'ridesIncluded', 'rideProductType'], product)
        const hasExpired = toDateTime(product.expireAt) < DateTime.local()

        if (!hasExpired) {
          await saveProductSubscription({ values: { profile: profile._id, product, startAt, ...subscriptionData } })
        }
      } else {
        showFeedback({ title: `No matching product found for ${productSku}`, type: 'error' })
      }
    } catch (error) {
      return { invite: result, status: 'error', error: error.message }
    }
  }

  const invitationResult = await actions.save(assocPath(['props', 'values', 'profile'], profile._id, context))
  result = mergeDeepRight(result, invitationResult)

  return result
}

export const changeRole = async ({ props: { id, userType }, invitationsService }) => {
  await invitationsService.patch(id, { userType })
}

export const send = async ({ props: { id }, invitationsService }) => {
  const result = await invitationsService.patch(id, { send: true })
  return { result }
}

export const setBulkQueue = ({ props, store, get }) => {
  const { queue } = props
  const packages = get(state`account.packages`)
  const isRideProductsEnabled = packages['ride-products']

  const invites = queue.map(
    ({ firstName, lastName, phoneNumber, role, associatedWith, trackAttendance, send = true, email, product, address, birthdate, levelOfCare = '' }) => {
      const data = {
        name: { first: firstName, last: lastName },
        userType: toLower(role),
        associatedWith,
        trackAttendance,
        send,
        email,
        address,
        levelOfCare,
        birthdate,
      }

      if (phoneNumber) {
        const masked = IMask.createMask({ mask: phoneMaskNoExtension })
        masked.resolve(phoneNumber)
        const phone = masked.value
        data.phone = phone
      }

      if (isRideProductsEnabled && product) {
        data.product = product
      }

      try {
        const invite = invitationSchema.validateSync(data)
        return { invite, status: 'ready' }
      } catch (error) {
        return { invite: data, status: 'error', error: error.message }
      }
    }
  )

  store.merge(moduleState`upload`, { queue: invites || [], created: 0, updated: 0, loading: false, success: null, error: '' })
}

export const processBulkQueue = async ({ store, get, app }) => {
  const saveInvitation = app.getSequence('invitations.saveBulk')
  const queue = get(moduleState`upload.queue`)
  store.set(moduleState`upload.loading`, true)
  await Bluebird.map(
    queue,
    async ({ invite }, index) => {
      try {
        // TODO: Only send invitations to users without existing invitations.
        await saveInvitation({ values: invite })
        store.increment(moduleState`upload.created`)
        store.set(moduleState`upload.queue.${index}.status`, 'success')
      } catch (error) {
        store.merge(moduleState`upload.queue.${index}`, { status: 'error', error: error.message })
      }
      await Bluebird.delay(500)
    },
    { concurrency: 5 }
  )
  store.set(moduleState`upload.success`, true)
  store.set(moduleState`upload.loading`, false)
}
