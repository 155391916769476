import { state } from "cerebral";
import { findIndex, propEq } from 'ramda';
import { seasonSchema } from "../../../shared/schemas/season-schema";
import apiClient from "../../lib/util/api-client";

const service = apiClient.service('seasons')

export const get = async ({ get, store }) => {
  const season = get(state`account.season`)
  const current = await service.get(season._id)
  const currentSeasons = get(state`account.currentUser.seasons`)
  const index = findIndex(propEq('_id', season._id), currentSeasons)
  if (index !== -1) {
    store.set(state`account.currentUser.seasons.${index}`, current)
  }
}

export const save = async ({ props: { values: season }, get, store }) => {
  const { _id } = season
  const { overrideData, ...data } = await service.patch(_id, seasonSchema.cast(season, { stripUnknown: true }))

  // Update override data on resort.
  const currentResorts = get(state`account.currentUser.resorts`)
  const resortIndex = findIndex(propEq('_id', data.resort), currentResorts)
  if (resortIndex !== -1) {
    store.merge(state`account.currentUser.resorts.${resortIndex}.overrideData`, overrideData)
  }

  // TODO: This is duplicated from above. DRY.
  const currentSeasons = get(state`account.currentUser.seasons`)
  const index = findIndex(propEq('_id', season._id), currentSeasons)
  if (index !== -1) {
    store.set(state`account.currentUser.seasons.${index}`, data)
  }
}
