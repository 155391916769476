import { state } from 'cerebral'
import { pluck, prop, reduce, toPairs, values } from 'ramda'
import { rruleDaysOfWeekIndexes, rruleDaysOfWeekKeys } from '../../../shared/schemas/fields/days-of-week'
import { liftScheduleSchema, liftResponseSchema } from '../../../shared/schemas/lift-schema'
import { crudActions, tagActions } from '../factories'

export const { find, setList, get, save, remove } = crudActions({ name: 'lifts', schemas: { find: liftResponseSchema } })

export const clearAllOverrides = async ({ liftsService, get }) => {
  const items = get(state`lifts.list.data`)
  if (items) {
    const ids = pluck('_id', items.map(get).filter(prop('hasOverride')))
    const lifts = await Promise.all(
      ids.map((id) => {
        return liftsService.patch(id, { statusOverride: '', statusTextOverride: '', statusOverrideDescription: '', override: {} })
      })
    )
    return { entities: { lifts } }
  }
}

export const setHasOverride = ({ props }) => {
  let {
    entities: { lifts = [], ...entities },
  } = props

  lifts = lifts.map((lift) => {
    lift.hasOverride = Boolean(lift.statusOverride || lift.override?.waitMinutes)
    return lift
  })

  return { entities: { ...entities, lifts } }
}

export const expandHoursToSchedule = ({ props }) => {
  let {
    entities: { lifts = [], ...entities },
  } = props

  lifts = lifts.map((lift) => {
    const { hours } = lift

    lift.schedules = values(reduce((result, [day, { open, close }]) => {
      if (!open || !close) {
        return result
      }

      const key = `${open}--${close}`
      const dayIndex = rruleDaysOfWeekIndexes[day]
      if (result[key]) {
        result[key].rrule.byweekday.push(dayIndex)
      } else {
        result[key] = liftScheduleSchema.cast({ start: open, end: close, rrule: { byweekday: [dayIndex] } })
      }
      return result
    }, {}, toPairs(hours)))

    return lift
  })

  return { entities: { ...entities, lifts } }
}

export const setHoursFromSchedule = ({ props }) => {
  const { values } = props
  const { schedules = [] } = values
  const hours = {}

  schedules.forEach(({ start: open, end: close, rrule: { byweekday } }) => {
    byweekday.forEach((dayIndex) => {
      hours[rruleDaysOfWeekKeys[dayIndex]] = { open, close }
    })
  })

  return { values: { ...values, hours } }
}

export const { collectTags } = tagActions()
