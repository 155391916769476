import { props } from 'cerebral'
import { set } from 'cerebral/factories'
import { compose, path, pluck } from 'ramda'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import { loadProfilesSequence } from '../profiles/factories'
import { sequences as route } from '../route'
import * as actions from './actions'

const loadProfiles = loadProfilesSequence(compose(pluck('profile'), path(['props', 'entities', 'eventAttendances'])), 'profileIds')

export const find = [
  actions.find,
  saveEntities(),
  loadProfiles,
]

export const findForList = [
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
  loadProfiles,
]

export const findIdsForInstance = [
  actions.findIdsForInstance,
]

export const get = [
  actions.get,
  saveEntities(),
  loadProfiles,
]

export const save = [
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Attendance saved', type: 'success' }),
  ({ props: { entities: { eventAttendances: [{ eventInstance } = {}] = [] } } }) => {
    return { params: { eventInstance } }
  },
  route.toEventAttendances,
]

export const saveWithoutFeedback = [
  //
  actions.save,
  saveEntities(),
]

export const remove = [
  actions.remove,
  showFeedback({ title: 'Attendance deleted', type: 'success' }),
  findForList,
]
