import { moduleState } from 'cerebral'
import { crudActions } from '../factories'
import { appreciationAwardResponseSchema } from '../../../shared/schemas/appreciation-award-schema'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'appreciationAwards' })

export const toggleReviewed = async ({ props: { id }, appreciationAwardsService: service, get }) => {
  if (id) {
    const current = get(moduleState`entities.${id}`)
    const { reviewed } = current
    let data = await service.patch(id, { reviewed: !reviewed })
    data = appreciationAwardResponseSchema.cast({ ...current, ...data })
    return { entities: { appreciationAwards: [data] } }
  }
}

export const toggleDeleted = async ({ props: { id }, appreciationAwardsService: service, get }) => {
  if (id) {
    const current = get(moduleState`entities.${id}`)
    const { deleted } = current
    let data = await service.patch(id, { deleted: !deleted })
    data = appreciationAwardResponseSchema.cast({ ...current, ...data })
    return { entities: { appreciationAwards: [data] } }
  }
}
